<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-sm-12"><h1>Trust & Verification</h1></div>
    </div>
    <div class="row">
      <div class="col-sm-12 bg-light">
        <ol class="py-3 m-0">
          <li>
            Getting your Verified ID is the easiest way to help build trust in the Trepr community.
          </li>
          <li>
            We'll verify you by matching information from an online account to an official ID.
          </li>
          <li>
            We believe anonymity erodes trust, so we verify the IDs of our Seekers and Trepers to
            help ensure the safety of our growing community.
          </li>
          <li>
            We'll ask for atlest 2 forms of IDs: official ID and online profile. This gives members
            more information to make a decision on Travel service requests that are sent or
            received.
          </li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h3>Verify photo ID</h3>
        <ErrorFormField :msgArray="errors.common" v-if="errors.common.length > 0" />
        <h4 class="text-muted text-center">
          <small>
            We allow {{ mbLimitedUploadSize }}MB per file and we support format : .png, .jpg, .jpeg.
            You can upload multi identity type at the same time .gif, .webp
          </small>
        </h4>
        <div class="row g-1">
          <div class="col-md-4" v-for="(item, index) in $data.identityList" :key="index">
            <div class="verfyid-list text-center" v-if="item.status == 1">
              <a href="#">
                <div class="verfyid-list-img bg-light">
                  <span
                    class="
                      position-absolute
                      top-0
                      end-0
                      translate-middle-y
                      badge
                      rounded-pill
                      bg-success
                    "
                  >
                    <i class="fa fa-check-circle"></i> APPROVED
                  </span>
                  <div
                    class="d-inline-block w-100 h-100"
                    :id="'image_preview_' + item.identity_type_id"
                  >
                    <img
                      :src="'/uploads/user_verification/small/' + item.image"
                      class="img-fluid d-inline-block"
                      style="height: 150px !important"
                    />
                  </div>
                </div>
              </a>
              <h5 class="text-success">{{ item.identity_name }}</h5>
            </div>
            <div class="verfyid-list text-center" v-else-if="item.status == 0">
              <a href="#">
                <div class="verfyid-list-img bg-light">
                  <span
                    class="
                      position-absolute
                      top-0
                      end-0
                      translate-middle-y
                      badge
                      rounded-pill
                      bg-warning
                    "
                  >
                    <i class="fa fa-clock"></i> AWAITING APPROVAL
                  </span>
                  <div
                    class="d-inline-block w-100 h-100"
                    :id="'image_preview_' + item.identity_type_id"
                  >
                    <img
                      :src="'/uploads/user_verification/small/' + item.image"
                      class="img-fluid d-inline-block"
                      style="height: 150px !important"
                    />
                  </div>
                </div>
              </a>
              <h5 class="text-warning">{{ item.identity_name }}</h5>
            </div>
            <div
              class="verfyid-list text-center"
              v-else-if="item.status == null || item.status === ''"
            >
              <a
                href="javascript:void(0)"
                @click="chooseFileClick($event, 'NotUploaded', item.identity_type_id)"
              >
                <div class="verfyid-list-img bg-light">
                  <span
                    class="
                      position-absolute
                      top-0
                      end-0
                      translate-middle-y
                      badge
                      rounded-pill
                      bg-dark
                    "
                  >
                    <i class="fa fa-times-circle"></i> NOT UPLOADED
                  </span>
                  <div
                    class="d-inline-block w-100 h-100"
                    :id="'image_preview_' + item.identity_type_id"
                  ></div>
                </div>
              </a>
              <div
                class="input-group mb-3"
                @change="
                  chooseFile($event, 'NotUploaded', item.identity_type_id, item.identity_name)
                "
              >
                <input
                  type="file"
                  class="form-control px-0 fs-6"
                  :id="'fileNotUploaded' + item.identity_type_id"
                  name="imageNotUploaded"
                  accept=".png, .jpg, .jpeg, .gif, .webp"
                />
                <label
                  class="input-group-text px-1"
                  for="'fileNotUploaded' + item.identity_type_id"
                >
                  <i class="fa fa-file-upload text-dark"> </i>
                </label>
              </div>
              <h5 class="text-dark">{{ item.identity_name }}</h5>
            </div>
            <div class="verfyid-list text-center" v-else-if="item.status == 2">
              <a
                href="javascript:void(0)"
                @click="chooseFileClick($event, 'Rejected', item.identity_type_id)"
                :title="'Rejected reason: ' + item.rejected_reason"
              >
                <div class="verfyid-list-img bg-light">
                  <span
                    class="
                      position-absolute
                      top-0
                      end-0
                      translate-middle-y
                      badge
                      rounded-pill
                      bg-danger
                    "
                  >
                    <i class="fa fa-ban"></i> REJECTED
                  </span>
                  <div
                    class="d-inline-block w-100 h-100"
                    :id="'image_preview_' + item.identity_type_id"
                  >
                    <img
                      :src="'/uploads/user_verification/small/' + item.image"
                      class="img-fluid d-inline-block"
                      style="height: 150px !important"
                    />
                  </div>
                </div>
              </a>
              <div
                class="input-group mb-3"
                id="igRejectedWrapper"
                @change="chooseFile($event, 'rejected', item.identity_type_id, item.identity_name)"
              >
                <input
                  type="file"
                  class="form-control px-0 fs-6"
                  :id="'fileRejected' + item.identity_type_id"
                  name="imageRejected"
                  accept=".png, .jpg, .jpeg, .gif, .webp"
                />
                <label class="input-group-text px-1" :for="'fileRejected' + item.identity_type_id">
                  <i class="fa fa-file-upload text-danger"> </i>
                </label>
              </div>
              <h5 class="text-danger">{{ item.identity_name }}</h5>
            </div>
          </div>
        </div>

        <div class="d-grid gap-2 col-6 mx-auto" v-if="$data.isShowBtnUpload">
          <button class="btn btn-primary" type="button" @click="btnUploadEvent">
            <LoadingIcons v-if="$data.ajaxLoad.normal" :total="6" />
            <span v-else>Upload Your Identity Images</span>
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <h3>Current verification</h3>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="d-flex">
              <div
                :class="{
                  'flex-grow-1': true,
                  'text-success': this.$store.state.email_verify == 1,
                  'text-danger': this.$store.state.email_verify != 1,
                }"
              >
                Email Address
              </div>
              <div class="" v-if="this.$store.state.email_verify == 1">
                <span class="badge rounded-pill bg-success">
                  <i class="fa fa-check-circle"></i> Aprroved
                </span>
              </div>
              <div class="" v-else>
                <span class="badge rounded-pill bg-danger">
                  <i class="fa fa-times-circle"></i> Not Verified
                </span>
              </div>
            </div>
            <div class="text-muted fs-6 bg-light text-justify p-1">
              <span v-if="this.$store.state.email_verify == 1">
                Your email has been confirmed already.
              </span>
              <span v-else>Your email hasn't been confirmed yet.</span>
              A confirmed email is important to allow us to securely communicate with you
            </div>
          </li>
          <li class="list-group-item">
            <div class="d-flex">
              <div
                :class="{
                  'flex-grow-1': true,
                  'text-success': this.$store.state.phone_verify == 1,
                  'text-danger': this.$store.state.phone_verify != 1,
                }"
              >
                Phone Number
              </div>
              <div class="" v-if="this.$store.state.phone_verify == 1">
                <span class="badge rounded-pill bg-success">
                  <i class="fa fa-check-circle"></i> Aprroved
                </span>
              </div>
              <div class="" v-else>
                <span class="badge rounded-pill bg-danger">
                  <i class="fa fa-times-circle"></i> Not Verified
                </span>
              </div>
            </div>
            <div class="text-muted fs-6 bg-light text-justify p-1">
              <span v-if="this.$store.state.email_verify == 1">
                Your phone number has been confirmed already.
              </span>
              <span v-else>Your phone number hasn't been confirmed yet.</span>. Rest assured, your
              number is only shared with another Trepr member once you have a confirmed travel
              service.
            </div>
          </li>
          <li class="list-group-item" v-for="(item, index) in $data.identityList" :key="index">
            <div class="d-flex" v-if="item.status == null || item.status === ''">
              <div class="flex-grow-1 text-dark">{{ item.identity_name }}</div>
              <div class="">
                <span class="badge rounded-pill bg-dark">
                  <i class="fa fa-times-circle"></i> NOT UPLOADED
                </span>
              </div>
            </div>
            <div class="d-flex" v-else-if="item.status == 0">
              <div class="flex-grow-1 text-warning">{{ item.identity_name }}</div>
              <div class="">
                <span class="badge rounded-pill bg-warning">
                  <i class="fa fa-clock"></i> AWAITING APPROVAL
                </span>
              </div>
            </div>
            <div class="d-flex" v-else-if="item.status == 1">
              <div class="flex-grow-1 text-success">{{ item.identity_name }}</div>
              <div class="">
                <span class="badge rounded-pill bg-success">
                  <i class="fa fa-check-circle"></i> APPROVED
                </span>
              </div>
            </div>
            <div class="d-flex" v-else-if="item.status == 2">
              <div class="flex-grow-1 text-danger">
                {{ item.identity_name }} <br />
                <small class="text-secondary">
                  Rejected Reason: <b>{{ item.rejected_reason }}</b>
                </small>
              </div>
              <div class="">
                <span class="badge rounded-pill bg-danger">
                  <i class="fa fa-ban"></i> REJECTED
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorFormField from '../../common/error/ErrorFormField';
import LoadingIcons from '../../common/LoadingIcons';
export default {
  name: 'TrustVerification',
  components: {
    ErrorFormField,
    LoadingIcons,
  },
  data: function () {
    return {
      errors: {
        common: [],
      },
      ajaxLoad: {
        normal: false,
      },
      identityList: [],
      isShowBtnUpload: false,
    };
  },
  computed: {
    mbLimitedUploadSize: function () {
      let limitedUploadSize = parseInt(process.env.VUE_APP_LIMITED_UPLOAD_SIZE);
      let mbSize = limitedUploadSize / 1000000;
      return mbSize;
    },
  },
  methods: {
    getListAgain: async function () {
      let apiUrl = process.env.VUE_APP_API_URL;
      let self = this;
      self.$data.countryList = [];
      let token = this.$store.state.token;
      let unique_id = this.$store.state.unique_id;
      await fetch(apiUrl + 'user/get-identity-list', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: `unique_id=${unique_id}`,
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log('Success:', resData);
          // let messages = resData.messages
          if (resData.status == 'error') {
            alert('error while geting data ' + resData.message);
            return;
          }
          self.$data.identityList = resData.identityList;
        })
        .catch(function (err) {
          console.log('error', err);
        });
    },
    chooseFileClick: function (event, type, typeId) {
      console.log('type ', type);
      console.log('file ', 'file' + type);
      document.getElementById('file' + type + typeId).click();
    },
    chooseFile: async function (event, type, idType, name) {
      // console.log('type', type);
      // console.log('event', event.target.id);
      // var id = event.target.id;
      var files = event.target.files;
      let limitedUploadSize = parseInt(process.env.VUE_APP_LIMITED_UPLOAD_SIZE);
      let mbSize = limitedUploadSize / 1000000;
      if (files && files[0]) {
        // console.log(this.files[0].size);
        if (files[0].size > limitedUploadSize) {
          // alert('We only allow maximum ' + mbSize.toString() + 'per file');
          this.$data.errors.common = [
            'We only allow maximum ' + mbSize.toString() + 'per file. Please choose a smaller file',
          ];
          return false;
        }
        this.$data.isShowBtnUpload = true;
        var reader = new FileReader();
        document.getElementById('image_preview_' + idType).innerHTML = '';
        reader.onload = function (e) {
          var img = document.createElement('IMG');
          img.src = e.target.result;
          img.setAttribute('class', 'img-fluid d-inline-block');
          img.setAttribute('style', 'height: 150px !important');
          document.getElementById('image_preview_' + idType).appendChild(img);
          var hiddenInput2 = document.createElement('input');
          hiddenInput2.value = name;
          hiddenInput2.setAttribute('type', 'hidden');
          hiddenInput2.setAttribute('id', 'data_type_name_' + idType);
          document.getElementById('image_preview_' + idType).appendChild(hiddenInput2);
          var hiddenInput = document.createElement('input');
          hiddenInput.value = e.target.result;
          hiddenInput.setAttribute('type', 'hidden');
          hiddenInput.setAttribute('id', 'data_image_preview_' + idType);
          document.getElementById('image_preview_' + idType).appendChild(hiddenInput);
        };
        reader.readAsDataURL(files[0]);
      }
    },
    btnUploadEvent: async function () {
      let identityList = this.$data.identityList;
      if (identityList.length > 0) {
        var objData = {};
        objData.unique_id = this.$store.state.unique_id;
        for (let i = 0; i < identityList.length; i++) {
          var element = document.getElementById(
            'data_image_preview_' + identityList[i].identity_type_id
          );
          if (typeof element != 'undefined' && element != null) {
            // Exists.
            console.log('Id has data ', element.id);
            objData[element.id] = element.value;
          }
          var element2 = document.getElementById(
            'data_type_name_' + identityList[i].identity_type_id
          );
          if (typeof element2 != 'undefined' && element2 != null) {
            // Exists.
            console.log('Id has data ', element2.id);
            objData[element2.id] = element2.value;
          }
        }
        const jQuery = require('jquery');
        //eslint-disable-next-line
        const $ = jQuery.noConflict();
        let apiUrl = process.env.VUE_APP_API_URL;
        let token = this.$store.state.token;
        let self = this;
        self.$data.ajaxLoad.normal = true;
        $.ajax({
          url: apiUrl + 'user/update-identity',
          type: 'POST',
          headers: { Authorization: 'Bearer ' + token },
          data: objData,
          error: function (err) {
            self.$data.ajaxLoad.normal = false;
            switch (err.status) {
              case '400':
                // bad request
                break;
              case '401':
                // unauthorized
                break;
              case '403':
                // forbidden
                break;
              default:
                //Something bad happened
                break;
            }
          },
          success: function (data) {
            console.log('change image Success!', data);
            // self.$emit('updateAvatar', data.newAvatarPath);
            // $('#btnCloseModal').click();
            self.$data.ajaxLoad.normal = false;
            self.$router.go();
          },
        });
      }
    },
  },
  async created() {
    await this.getListAgain();
  },
};
</script>

<style lang="scss" scoped>
.verfyid-list {
  position: relative;
}
.verfyid-list-img {
  height: 150px !important;
}
.topright {
  position: absolute;
  top: 8px;
  right: 16px;
}
</style>
